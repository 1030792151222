
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardHeader, useAccordionButton } from "react-bootstrap";
import css from "./SkillTree.module.css";
import windows from "../../static/icons/windows.png"
import linux from "../../static/icons/linux.png"
import cpp from "../../static/icons/cpp.png"
import bash from "../../static/icons/bash.png"
import java from "../../static/icons/java.png"
import assembly from "../../static/icons/assembly.png"
import nodejs from "../../static/icons/nodejs.png"
import springboot from "../../static/icons/springboot.png"
import agile from "../../static/icons/agile.png"
import scrum from "../../static/icons/scrum.png"
import react from "../../static/icons/reactjs.png"
import kotlin from "../../static/icons/kotlin.png"
import python from "../../static/icons/python.png"
import sql from "../../static/icons/sql.png"
import pentesting from "../../static/icons/pentesting.png"
import redteam from "../../static/icons/redteam.png"
import malware from "../../static/icons/malware.png"
import reversing from "../../static/icons/reverseengineering.png"
import shield from "../../static/icons/shield.png"
import system from "../../static/icons/system.png"
import webapplication from "../../static/icons/webapplication.png"
import project from "../../static/icons/project.png"

import { useState } from "react";

const SkillTree = () => {

    const [openAccord, setOpenAccord] = useState(null);

    const CustomAccordion = ({ children, eventKey }) => {

        const decoratedOnClick = useAccordionButton(eventKey)
    
        return (
            <>
                <a style={{ cursor: "pointer"}}
                    onClick={decoratedOnClick}>{children}</a>
            </>
        )
    }

    return (

        <div className={css.skillTree}>
        <Accordion className={css.box} activeKey={openAccord == 2 ?"2" : null} onClick={() => setOpenAccord(2)} onMouseEnter={() => setOpenAccord(2)} onMouseLeave={() => setOpenAccord(null)}>
            <AccordionItem eventKey="2">
                    <Card bg="dark" style={{ color: "white", borderRadius: 0}} >
                    <CustomAccordion>
                            <CardHeader style={{padding: "10px"}}>
                            <span className={css.titleCategory}>Cyber Security <img src={shield} width="30px" height="auto"/></span>
                            </CardHeader>
                    </CustomAccordion>
                        </Card>

                <AccordionBody style={{color: "black"}}>                    
                    <ul className={css.skillList}>        
                    <li>

                        <div className={css.skillBox}>
                            <img src={pentesting} className={css.skillImage}></img>
                            &nbsp;
                            <span className={css.titleSkill}>Pentesting</span>
                        </div>
                    </li>
                    <li>
                        <div className={css.skillBox}>
                            <img src={redteam} className={css.skillImage}></img>
                            &nbsp;
                            <span className={css.titleSkill}>Red Teaming</span>
                        </div>
                    </li>
                    <li>
                        <div className={css.skillBox}>
                            <img src={reversing} className={css.skillImage}></img>
                            &nbsp;
                            <span className={css.titleSkill}>Reverse Engineering</span>
                        </div>
                    </li>
                    <li>
                        <div className={css.skillBox}>
                            <img src={malware} className={css.skillImage}></img>
                            &nbsp;
                            <span className={css.titleSkill}>Malware Development</span>
                        </div>
                    </li>
                    </ul>
                </AccordionBody>
            </AccordionItem>
        </Accordion>
        
        <Accordion className={css.box} activeKey={openAccord == 0 ?"0" : null} onClick={() => setOpenAccord(0)} onMouseEnter={() => setOpenAccord(0)} onMouseLeave={() => setOpenAccord(null)}>
            <AccordionItem eventKey="0">
                <Card bg="dark" style={{ color: "white", borderRadius: 0}} >
                    <CustomAccordion>
                            <CardHeader style={{padding: "10px"}}>
                            <span className={css.titleCategory}>System programming <img src={system} width="30px" height="auto"/></span>
                            </CardHeader>
                    </CustomAccordion>
                </Card>

                <AccordionBody style={{color: "black"}}>                    
                    <ul className={css.skillList}>        
                    <li>

                        <div className={css.skillBox}>
                            <img src={windows} className={css.skillImage}></img> 
                            &nbsp;
                            <img src={cpp} className={css.skillImage}></img>
                            <span className={css.titleSkill}>Win32 C/C++</span>
                        </div>
                    </li>
                    <li>
                        <div className={css.skillBox}>
                            <img src={linux} className={css.skillImage}></img> 
                            &nbsp;
                            <img src={cpp} className={css.skillImage}></img>
                            <span className={css.titleSkill}>Unix C/C++</span>
                        </div>
                    </li>
                    <li>
                        <div className={css.skillBox}>
                            <img src={bash} className={css.skillImage}></img> 
                            &nbsp;
                            <span className={css.titleSkill}>Bash</span>
                        </div>
                    </li>
                    <li>
                        <div className={css.skillBox}>
                            <img src={assembly} className={css.skillImage}></img> 
                            &nbsp;
                            <span className={css.titleSkill}>Assembly</span>
                        </div>
                    </li>
                    <li>
                        <div className={css.skillBox}>
                            <img src={java} className={css.skillImage}></img> 
                            &nbsp;
                            <span className={css.titleSkill}>Java</span>
                        </div>
                    </li>
                    <li>
                        <div className={css.skillBox}>
                            <img src={python} className={css.skillImage}></img> 
                            &nbsp;
                            <span className={css.titleSkill}>Python</span>
                        </div>
                    </li>
                    </ul>
                </AccordionBody>
            </AccordionItem>
        </Accordion>

        <Accordion className={css.box} activeKey={openAccord == 1 ?"1" : null} onClick={() => setOpenAccord(1)} onMouseEnter={() => setOpenAccord(1)} onMouseLeave={() => setOpenAccord(null)}>
            <AccordionItem eventKey="1">
                    <Card bg="dark" style={{ color: "white", borderRadius: 0}} >
                        <CustomAccordion>
                                <CardHeader style={{padding: "10px"}}>
                                    <span className={css.titleCategory}>Application programming <img src={webapplication} width="30px" height="auto"/></span>
                                </CardHeader>
                        </CustomAccordion>
                    </Card>

                <AccordionBody style={{color: "black"}}>                    
                    <ul className={css.skillList}>        
                    <li>

                        <div className={css.skillBox}>
                            <img src={nodejs} className={css.skillImage}></img>
                            &nbsp;
                            <span className={css.titleSkill}>NodeJS</span>
                        </div>
                    </li>
                    <li>
                        <div className={css.skillBox}>
                            <img src={react} className={css.skillImage}></img>
                            &nbsp;
                            <span className={css.titleSkill}>ReactJS</span>
                        </div>
                    </li>
                    <li>
                        <div className={css.skillBox}>
                            <img src={kotlin} className={css.skillImage}></img>
                            &nbsp;
                            <span className={css.titleSkill}>Kotlin</span>
                        </div>
                    </li>
                    <li>
                        <div className={css.skillBox}>
                            <img src={java} className={css.skillImage}></img>
                            &nbsp;
                            <img src={springboot} className={css.skillImage}></img>
                            <span className={css.titleSkill}>Java Spring Boot</span>
                        </div>
                    </li>
                    <li>
                        <div className={css.skillBox}>
                            <img src={sql} className={css.skillImage}></img>
                            &nbsp;
                            <span className={css.titleSkill}>SQL</span>
                        </div>
                    </li>
                    </ul>
                </AccordionBody>
            </AccordionItem>
        </Accordion>

        <Accordion className={css.box} activeKey={openAccord == 4 ?"4" : null} onClick={() => setOpenAccord(4)} onMouseEnter={() => setOpenAccord(4)} onMouseLeave={() => setOpenAccord(null)}>
            <AccordionItem eventKey="4">
                    <Card bg="dark" style={{ color: "white", borderRadius: 0}} >
                    <CustomAccordion>
                            <CardHeader style={{padding: "10px"}}>
                            <span className={css.titleCategory}>Project Management <img src={project} width="30px" height="auto"/></span>
                            </CardHeader>
                    </CustomAccordion>
                        </Card>

                <AccordionBody style={{color: "black"}}>                    
                    <ul className={css.skillList}>        
                    <li>

                        <div className={css.skillBox}>
                            <img src={scrum} className={css.skillImage}></img>
                            &nbsp;
                            <span className={css.titleSkill}>Scrum</span>
                        </div>
                    </li>
                    <li>
                        <div className={css.skillBox}>
                            <img src={agile} className={css.skillImage}></img>
                            &nbsp;
                            <span className={css.titleSkill}>Agile</span>
                        </div>
                    </li>
                    </ul>
                </AccordionBody>
            </AccordionItem>
        </Accordion>
        </div>
    )
}

export default SkillTree;