// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.SkillTree_box__1-jwb {
    max-width: 500px;
    height: auto;
    margin: 20px;
    width: auto;
}
.SkillTree_skillList__h4p4V {
    list-style: none;

}

.SkillTree_skillBox__IUY8i {
    display: flex;
    padding: 10px;
}

.SkillTree_titleSkill__NxVer {
    padding: 10px;
}

.SkillTree_titleCategory__uFdLs {
    font-size: 1em;
}

/* For phones */
@media screen and (min-width: 600px) {
    .SkillTree_skillTree__s7vFL {
        display: flex;
    }
}

/* For smaller screens */
@media screen and (min-width: 1000px) {
    .SkillTree_titleCategory__uFdLs{
        font-size: 1.5em;
    }
}  

.SkillTree_skillImage__qoZLn {
    width: 50px;
    height: 50px;
}

`, "",{"version":3,"sources":["webpack://./src/components/SkillTree/SkillTree.module.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,WAAW;AACf;AACA;IACI,gBAAgB;;AAEpB;;AAEA;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA,eAAe;AACf;IACI;QACI,aAAa;IACjB;AACJ;;AAEA,wBAAwB;AACxB;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":["\n.box {\n    max-width: 500px;\n    height: auto;\n    margin: 20px;\n    width: auto;\n}\n.skillList {\n    list-style: none;\n\n}\n\n.skillBox {\n    display: flex;\n    padding: 10px;\n}\n\n.titleSkill {\n    padding: 10px;\n}\n\n.titleCategory {\n    font-size: 1em;\n}\n\n/* For phones */\n@media screen and (min-width: 600px) {\n    .skillTree {\n        display: flex;\n    }\n}\n\n/* For smaller screens */\n@media screen and (min-width: 1000px) {\n    .titleCategory{\n        font-size: 1.5em;\n    }\n}  \n\n.skillImage {\n    width: 50px;\n    height: 50px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `SkillTree_box__1-jwb`,
	"skillList": `SkillTree_skillList__h4p4V`,
	"skillBox": `SkillTree_skillBox__IUY8i`,
	"titleSkill": `SkillTree_titleSkill__NxVer`,
	"titleCategory": `SkillTree_titleCategory__uFdLs`,
	"skillTree": `SkillTree_skillTree__s7vFL`,
	"skillImage": `SkillTree_skillImage__qoZLn`
};
export default ___CSS_LOADER_EXPORT___;
