import { Card, Container } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import css from "./BlogPostPage.module.css"
import imageHandshake from "../../static/img/handshake.png"
import React from "react";

const BlogPostPage = () => {
    
    const params = useParams();

    const OdysseusFramework = () => (
        <>

            {/* 
                Introduction
            
            */}
            <h1>Odysseus C2 Framework </h1>
            <div className={css.introduction}>
                <b>
                After my graduation I had a lot of spare time during my holidays.
                I knew that I was interested in malware development and reverse engineering, 
                that is why I started this project. Enjoy this blog post in which I will tell you
                about my journey of writing a command and control server and what I have learned so far.
                </b>
            </div>

            {/* First alinea */}

            <div className={css.paragraphPicture}>
                <div className={css.paragraph} style={{marginTop: "10px"}}>
                    To start creating a C2 framework we need to figure out what the architecture of the 
                    command and control server, clients and implants. The important componentes to remember are
                    the <b className={css.blue}>implant</b>, the <b className={css.gray}>client</b> and the <b className={css.red}>server</b>.
                    <br/><br/>
                    The <b className={css.blue}>implant</b> infects the computer and connects back to the server, which can 
                    take <b>Beacon Object Files</b> (<b>BOFs</b>) in memory and execute it. The <b className={css.blue}>implant</b> connects to the C2 server
                    on a specific ip address and requests for the <b>BOFs</b>. The <b className={css.red}>server</b> controls all the <b className={css.blue}>implants </b>
                    and is responsible for compiling the <b>BOFs</b> and all the code execution. The
                    <b className={css.gray}> client</b> is responsible for the interaction between the <b className={css.red}>C2 server</b> and the <b className={css.blue}>implants </b>. 
                 </div>
                <div>
                    <Card className={css.picture}>
                        <Card.Body>
                            <img className={css.image} onClick={() => window.location.href = "https://raw.githubusercontent.com/Trisna22/Odysseus/main/documentation/odysseus_architecture.png"} src="https://raw.githubusercontent.com/Trisna22/Odysseus/main/documentation/odysseus_architecture.png" alt="architecture design"></img>
                            <i className={css.paragraph}>
                                Figure: Odysseus Architecture design
                            </i>
                        </Card.Body>
                        
                    </Card>
                </div>
            </div>

            {/* Chapter 1: Implant */}
 
            <h4 className={css.paragraphTitle}>1. Developing the implant</h4>
            <div className={css.paragraphPicture}>
                <div>
                    <Card className={css.picture}>
                        <Card.Body>
                            <img className={css.image} onClick={() => window.location.href = "https://raw.githubusercontent.com/Trisna22/Odysseus/main/documentation/class_diagram_ody.png"} src="https://raw.githubusercontent.com/Trisna22/Odysseus/main/documentation/class_diagram_ody.png" alt="architecture design"></img>
                            <i className={css.paragraph}>
                                Figure: Class Diagram
                            </i>
                        </Card.Body>
                    </Card>
                </div>
                <div className={css.paragraph}>
                The first objectieve is to start creating an <b className={css.blue}>implant</b> that can handle <b>BOFs</b> and is fully interactive 
                with our <b className={css.red}>C2 server</b>. I have decided to write the <b className={css.blue}>implant</b> in C++, so that it can be cross-platform compiled and
                because C++ is my favourite programming language. For EDR evasion the communication between the <b className={css.red}>server</b> and the <b className={css.blue}>implants</b> is going through the
                HTTP protocol and it makes it easier to handle the object transfer. The class diagram of the latest <b className={css.blue}>implant</b> is in the picture next to this paragraph.
                <br/> <br/>
                As previously mentioned, communication occurs via HTTP protocol, which is selected for its widespread usage across networks and non-suspicious nature, reducing the likelihood of
                detection by Endpoint Detection and Response (EDR) systems. Moreover, integrating TLS with the HTTP protocol can further enhance traffic encryption for added security.</div>
            </div>

            {/* Chapter 1.1: HTTP handshake */}

            <h5 className={css.paragraphTitle}>1.1 HTTP handshake</h5>
            <div className={css.paragraphPicture}>
                <div>
                    <Card className={css.picture}>
                        <Card.Body>
                            <img className={css.image} onClick={() => window.location.href = "https://raw.githubusercontent.com/Trisna22/Odysseus/main/documentation/handshake.png"} src="https://raw.githubusercontent.com/Trisna22/Odysseus/main/documentation/handshake.png" alt="handshake"></img>
                            <i className={css.paragraph}>
                                Figure: Handshake
                            </i>
                        </Card.Body>
                    </Card>
                </div>
                <div className={css.paragraph}>
                The <b className={css.blue}>implant</b> makes an initial connection to the <b className={css.red}>C2 server</b> and exchanges the ID and information about the host.
                During the initial handshake the <b className={css.blue}>implant</b> looks if an initial job exists, when it does it will retrieve the object and executes it in memory.
                (More details later in the blogpost). The <b>BOFs</b> are sent over HTTPs transport, but are for encrypted on their own as well for an extra layer of security.
                <br/>
                Once <b>BOFs</b> are executed, the results are transmitted to the <b className={css.red}>C2 server</b>. The <b className={css.gray}>client</b> has the capability to initiate a shell,
                where the output of these jobs are displayed and further objects can be executed as commands. The <b className={css.red}>C2 server</b> maintains a queue for the implants to download, a 
                process that can be controlled via the <b className={css.gray}>client</b>'s shell interface.
                </div>
            </div>

            {/* Chapter 1.2: BOF */}

            <h5>1.2 Beacon Object Files</h5>
            <div className={css.paragraph}>
                In this paragraph we are talking about <b>Beacon Object Files</b> 

                <li>- loading process (ELF & PE)</li>
                <li>- taken from trustedsec</li>
                <li>- (reference to CoughLoader)</li>
                <li>- Little insight about sock5 proxy BOF</li>
            </div>
            <div>
                <img class="wideImage" onClick={() => window.location.href = "https://raw.githubusercontent.com/Trisna22/Odysseus/main/documentation/process_BOF.png"} src="https://raw.githubusercontent.com/Trisna22/Odysseus/main/documentation/process_BOF.png" alt="BOF"></img>
            </div>
               <i>Figure: BOF loading</i>

            {/* Chapter 1.3: Evasion techniques */}
               
            <h5>1.3 Evasion techniques</h5>

            {/* Chapter 1.3.1: Anti static analysis */}
            
            <h6>1.3.1 Anti static analysis</h6>

            {/* Chapter 1.3.2: Anti debugging */}

            <h6>1.3.2 Anti debugging </h6>
            <ol>
                <li>Communication with server (handshake process)</li>
                <li>BOF loading</li>
                <li>
                Anti av/edr evasion
                    <ul>
                    <li>Static analysis random</li>
                    <li>Anti debugging tricks</li>
                </ul></li>
            </ol>
            <div className={css.paragraph}>
                <h4>Building the Command & Control server</h4>
                The server is written in NodeJS 
                <ol>
                    <li>Terminal interface process</li>
                    <li>Cross compiling BOF</li>
                </ol>
            </div>
        </>
    )

    const LockFile = () => (
        <>
            <h1>LockFile</h1>
        </>
    )

    const BlogNotFound = () => (
        <>
            <h1>Sorry blogpost not found!</h1>
        </>
    )

    const DynamicBlog = () => {

        if (params.titleId === "odysseus-c2-framework") {
            return <OdysseusFramework/>;
        }
        else if (params.titleId === "LockFile") {
            return <LockFile/>
        }
        else {
            return <BlogNotFound/>
        }
    }

    return (
        <Container>
            <DynamicBlog/>
        </Container>
    )
}

export default BlogPostPage;


    /**
     *
     * 1. Inleiding:

    Introduceer het onderwerp van de blogpost: de malware die je hebt geschreven.
    Leg kort uit waarom je ervoor hebt gekozen om deze malware te ontwikkelen.

2. Achtergrond:

    Geef wat context over de huidige staat van cyberbeveiliging en het belang ervan.
    Bespreek misschien een aantal recente trends of gebeurtenissen in de wereld van malware en cyberdreigingen.

3. Beschrijving van de malware:

    Geef een gedetailleerde beschrijving van de malware die je hebt geschreven.
    Leg uit hoe het werkt, welke systemen het beïnvloedt en welke schade het kan veroorzaken.
    Vermeld de technieken en methoden die je hebt gebruikt om de malware te ontwikkelen.

4. Motivatie:

    Bespreek waarom je ervoor hebt gekozen om deze malware te maken.
    Was het een leerervaring, een onderzoeksproject of had het een andere reden?
    Wees eerlijk over je motivatie zonder de illegaliteit of ethische implicaties te bagatelliseren.

5. Impact en risico's:

    Bespreek de potentiële impact van de malware op getroffen systemen en gebruikers.
    Benadruk de mogelijke risico's en schade die het kan veroorzaken.
    Benadruk het belang van bewustzijn en preventie in cyberbeveiliging.

6. Lessen en reflecties:

    Bespreek wat je hebt geleerd tijdens het ontwikkelingsproces van de malware.
    Reflecteer op eventuele ethische dilemma's die je bent tegengekomen.
    Geef advies aan anderen die geïnteresseerd zijn in malware-analyse of -ontwikkeling.

7. Conclusie:

    Concludeer je blogpost met een samenvatting van de belangrijkste punten.
    Moedig lezers aan om na te denken over de impact van cyberdreigingen en het belang van goede beveiligingspraktijken.

8. Bronnen en aanvullende informatie:

    Voeg indien van toepassing links toe naar bronnen of artikelen die je hebt gebruikt tijdens je onderzoek.
    Geef aanvullende informatie of middelen voor lezers die meer willen weten over het onderwerp.

9. Aanmoediging tot discussie:

    Moedig lezers aan om vragen te stellen, opmerkingen achter te laten of hun eigen ervaringen te delen in de reactiesectie van de blogpost.
     */