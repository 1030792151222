
const NotFoundPage = () => {


    return (
        <>
        <h1>Sorry, page not found!</h1>
        </>
    )
}

export default NotFoundPage;