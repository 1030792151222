import { Col, Container, Row } from "react-bootstrap";
import { LiaCopyrightSolid } from "react-icons/lia";
import styles from "./footer.module.css"

const Footer = () => {

    const titleStyle = {
        padding: "10px",
        fontSize: "20px"
    }

    const footerStyle = {
        backgroundColor: "black", 
        color: "white", 
        display: "flex", 
        backgroundColor: "#212529",
        minHeight: "350px"
    }
    
    return (
        <div className={styles} style={footerStyle}>
            <Container style={{marginTop: "20px"}}>
                <Row>
                    <Col>
                        <Row><span style={titleStyle}><u>CyberBear</u></span></Row>
                        <ul>
                            <li><a href="/blog">Blog</a></li>
                        </ul>
                    </Col>
                    <Col>
                        <Row><span style={titleStyle}><u>Information</u></span></Row>
                        <ul>
                            <li><a href="/skills">Skillset</a></li>
                        </ul>
                    </Col>
                    <Col>
                        <Row><span style={titleStyle}><u>Contact</u></span></Row>
                        <ul>
                        <li><a href="/contact">Contact form</a></li>
                        <li><a href="https://www.linkedin.com/in/trisna-quebe/">LinkedIn</a></li>
                        <li><a href="mailto:info@cyberbear.nl">E-mail</a></li>

                        </ul>
                        </Col>
                </Row>
                <Row>
                    <span style={{padding: "20px"}}><LiaCopyrightSolid/> 2023 CyberBear</span>
                </Row>
            </Container>
        </div> 
    )
}

export default Footer;