import { Badge, Col, Row } from "react-bootstrap";


const BlogElement = (props) => {

    const boxStyle = {
        boxShadow: "0px 0px 7px 3px rgba(110,106,110,1)", 
        padding: "20px",
        margin: "20px",
        borderRadius: "5px",
        maxWidth: "1000px",
        textAlign: "left"
    }

    const linkStyle = {
        fontSize: "20px",
        color: "#212529"
    }
    
    return (
        <div style={boxStyle}>
            <Row>
                <Col>
                    <a style={linkStyle} href={props.url}>{props.title}</a> 
                </Col>
                <Col style={{textAlign: "center"}}>
                    {
                        props.tags.map((tag) => (
                            <Badge bg="dark"> {tag} </Badge>
                        ))
                    }
                </Col>
            </Row>
            <Row style={{borderBottom: "1px solid #ccc"}}>
                <Col>
                    {props.description}
                </Col>
                <Col style={{textAlign: "center"}}>
                    <img src={props.image} style={{cursor: "pointer"}} onClick={() => window.location.href = props.url} width="250" height="250"/>
                </Col>
            </Row>
            <Row>
                <p>Posted on: <i>{props.date} by {props.author}</i></p>
            </Row>
        </div>
    )
}

export default BlogElement;