// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.SkillsPage_certificationDiv__y7Lhx {
    cursor: pointer;

}

.SkillsPage_certBox__gJJyq {
    margin: 10px;
}

@media screen and (min-width: 600px) {

    .SkillsPage_certificationDiv__y7Lhx {    
        display: flex;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Skills/SkillsPage.module.css"],"names":[],"mappings":";AACA;IACI,eAAe;;AAEnB;;AAEA;IACI,YAAY;AAChB;;AAEA;;IAEI;QACI,aAAa;IACjB;AACJ","sourcesContent":["\n.certificationDiv {\n    cursor: pointer;\n\n}\n\n.certBox {\n    margin: 10px;\n}\n\n@media screen and (min-width: 600px) {\n\n    .certificationDiv {    \n        display: flex;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"certificationDiv": `SkillsPage_certificationDiv__y7Lhx`,
	"certBox": `SkillsPage_certBox__gJJyq`
};
export default ___CSS_LOADER_EXPORT___;
