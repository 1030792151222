import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavigationBar from './components/NavigationBar/NavigationBar';
import HomePage from './pages/Home/HomePage';
import NotFoundPage from './pages/NotFound/NotFoundPage';
import SkillsPage from './pages/Skills/SkillsPage';
import BlogPage from './pages/Blog/BlogPage';
import Footer from './components/Footer/Footer';
import BlogPostPage from './pages/Blog/BlogPostPage';
import ContactPage from './pages/Contact/ContactPage';

export default function App() {
  return (
    <BrowserRouter>
    <NavigationBar/>
     <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/skills" element={<SkillsPage/>}/>
        <Route path="/blog" element={<BlogPage/>}/>
        <Route path="/blog/:titleId" element={<BlogPostPage/>}/>
        <Route path="/contact" element={<ContactPage/>}/>
        <Route path="*" element={<NotFoundPage/>}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
