import { Button, Card, Col, Container, FloatingLabel, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { BsBuildingsFill } from "react-icons/bs";
import { MdAttachEmail } from "react-icons/md";
import { CiBarcode } from "react-icons/ci";
const ContactPage = () => {
    
    return (
        <Container style={{padding: "20px"}}>
            <div>
                <h1 style={{
                    borderLeft: "3px solid black",
                    padding: "10px"
                }}>Get in touch!</h1>
                <span>Want to have a cup of coffie or talk about tech?<span/> Don't hesitate to contact me. You can always send an email to  
                <a href="mailto:info@cyberbear.nl">info@cyberbear.nl</a><br/>
                Or fill out the contact form and we will reach out to you.</span>
                </div>
                <Row>
                    <Col>
                    <Card style={{marginTop: "30px"}}>
                        <Card.Body>
                            <Card.Title>Contact form</Card.Title>
                            <Card.Text>
                                <Form>
                                    <Row>
                                        <InputGroup>
                                            <Form.Control placeholder="Name"></Form.Control>
                                        </InputGroup>
                                    </Row>
                                    <Row>
                                        <InputGroup>
                                            <FormControl placeholder="Email or phonenumber"></FormControl>
                                        </InputGroup>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <InputGroup>
                                            <FormControl as="textarea" placeholder="Message or question"></FormControl>
                                        </InputGroup>
                                    </Row>
                                </Form>
                            </Card.Text>
                            <Button variant="outline-dark">Submit</Button>
                            <span style={{color: "red"}}>Sorry, the form is disabled!</span>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col>
                    <Row>
                        <b>Contact information</b>
                    </Row>
                    <div style={{padding: "10px"}}>
                    <span><MdAttachEmail/><a href="mailto:info@cyberbear.nl"> info@cyberbear.nl</a></span><br/>
                    </div>
                    </Col>
                </Row>
        </Container>
    )
}

export default ContactPage;