import { Link, NavLink } from "react-router-dom"
import logo from "../../static/img/brand_white_letters.png"
import {Container, Nav, NavDropdown, Navbar} from "react-bootstrap"
import {FaLinkedin} from "react-icons/fa"
import {ImMail4} from "react-icons/im"

const NavigationBar = () => {

    return (
        <Navbar variant="dark" bg="dark" expand="sm">
            <Container>
                <Navbar.Brand  href="/"><img src={logo} alt="CyberBear logo" height="100"/></Navbar.Brand>
                <Navbar.Toggle aria-controls="navigationBar" style={{marginRight: "20px"}}/>
                <Navbar.Collapse id="navigationBar">
                    <Nav variant="underline" defaultActiveKey="/">
                        <Nav.Link href="/skills" eventKey="link0">Skills</Nav.Link>
                        <Nav.Link href="/blog" eventKey="link1">Blog</Nav.Link>
                        <Nav.Link href="/contact" eventKey="link2">Contact me</Nav.Link>
                        
                        <Nav.Link href="https://www.linkedin.com/in/trisna-quebe/" ><FaLinkedin style={{fontSize: "30px"}}/></Nav.Link>
                        <Nav.Link href="mailto:info@cyberbear.nl" ><ImMail4 style={{fontSize: "30px"}}/></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavigationBar;