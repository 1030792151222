import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./HomePage.module.css";
import { useEffect, useState } from "react";
import amsterdam from "../../static/icons/amsterdam.png"
import netherlands from "../../static/icons/netherlands.png"

const HomePage = () => {


    const skills = [
        "an Ethical Hacker    ",
        "a Motorcyclist   ",
        "a Security Engineer   ",
        "a Reverse Engineer   ",
        "a Software Engineer   ",
        "a Malware Developer   ",
        "a Fitnesser   "
    ]

    const colours = [
        "#149414", // green
        "#ffd700",// gold
        "#0db4ba",// cyan
        "#a32950",// purple
        "yellow", 
        "red",
        "#bad4d8",// 
    ]

    const [skillset, setSkillSet] = useState("");
    const [fontColor, setFontColor] = useState("");

    const typeLetters = () => {
        
        let lettersToUse = "";
        let i = 0, j = 0;
        var ending = false, deleting = false;

        setInterval(() => {

            
            if (ending) {
                if (j == skills.length -1) {
                    j = 0;
                } else {
                    j++;
                }

                ending = false;
            }

            let word = skills[j];
            setFontColor(colours[j])
            
            if (i < word.length && !deleting) {
                lettersToUse += word[i];
                setSkillSet(lettersToUse);
                i++;
            } else if (i >= word.length) {
                deleting = true; // Check if we need to delete now.
            }


            if (i > 0 && deleting) {
                lettersToUse = lettersToUse.slice(0, -1);
                setSkillSet(lettersToUse);
                i--;
            }
            
            if (i == 0 && deleting) {
                ending = true;
                deleting = false;
            }
        }, 130);
    }

    useEffect(() => {
        typeLetters();

    }, [])
    
    return (
        <div className={styles.backgroundPage}>
            <div className={styles.informationDiv}>
                <h1 className={styles.title}><b>
                    Hello everyone, my name is Trisna Quebe
                </b>
                </h1>
    
                <div className={styles.typingContainer}>
                    <span className={styles.sentence}>I'm <span style={{color: fontColor}}>{skillset}</span></span>
                    <span className={styles.inputCursor}></span>
                </div>

                <div className={styles.locatedDiv}>
                    <h4>Located in &nbsp;

                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Amsterdam</Tooltip>}>
                            <img width="50px" height="auto" src={amsterdam} alt="Amsterdam"></img>
                        </OverlayTrigger>
                     &nbsp;
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Netherlands</Tooltip>}>
                            <img width="50px" height="auto" src={netherlands} alt="Netherlands"></img>
                        </OverlayTrigger>
                    </h4>
                </div>

                <div className={styles.actionButtons}>
                    <Button variant="primary" href="/skills">View my Skills</Button>
                    <Button variant="primary" href="/blog">My Blog</Button>
                    <Button variant="outline-light">Check out my Résumé</Button>
                </div>
                 
            </div>
        </div>
    )
}

export default HomePage;