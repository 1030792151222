import { Container } from "react-bootstrap";

import image1 from "../../static/img/odysseus_architecture.png"
import image2 from "../../static/img/sslsocket.png"
import image3 from "../../static/img/weepingAngel.png"
import image4 from "../../static/img/lockfile.png"
import image5 from "../../static/img/fishbowl.png"
import image6 from "../../static/img/uacflooder.png"
import BlogElement from "../../components/Blog/BlogElement";

const BlogPage = () => {

    const titleStyle = {
        borderLeft: "3px solid black",
        padding: "10px",
        margin: "10px"
    }

    const blogs = [
        {
            title: "Odysseus C2 framework",
            description: "Odysseus the command & control framework that makes use of object loading. The framework generates implants for both Windows and Linux machines. The objects are beacons that can be written in C/C++.",
            url: "/blog/odysseus-c2-framework",
            date: "06-12-2023",
            author: "Tris.Q",
            image: image1,
            tags: [
                "Red Teaming", "Malware", "Blog"
            ]
        },
        {
            title: "FishBowl app",
            description: "A silly drinking game written in Kotlin for friends and family.",
            url: "https://github.com/Trisna22/FishBowl",
            date: "22-08-2023",
            author: "Tris.Q",
            image: image5,
            tags: [
                "Fun", "Android App development", "Source code"
            ],
        },
        {
            title: "LockFile",
            description: "A file encryption tool that makes use of both AES and RSA. The tool can encrypt your personal folders recursively.",
            url: "/blog/LockFile",
            date: "14-06-2022",
            author: "Tris.Q",
            image: image4,
            tags: [
                "File encryption", "AES/RSA" ,"Blog"
            ],
        },
        {
            title: "WeepingAngel PHP webshell",
            description: "WeepingAngel is a webshell written in PHP. The soure code for the webshell is obfuscated and encrypted.",
            url: "https://github.com/Trisna22/WeepingAngel",
            date: "31-01-2022",
            author: "Tris.Q",
            image: image3,
            tags: [
                "Web upload", "Shell", "Source code"
            ],
        },
        {
            title: "SSLSocket example",
            description: "An example for creating secured socket connections in C++. The source code contains examples to write encrypted connections with SSL/TLS encryption.",
            url: "https://github.com/Trisna22/SSLSocket",
            date: "02-10-2020",
            author: "Tris.Q",
            image: image2,
            tags: [
                "Networking", "SSL", "Source code",
            ]
        },
        {
            title: "UAC Flooder",
            description: "Social engineering tool that prompts for UAC until the user accepts the prompt. This code gives an example how to implant in your malware.",
            url: "https://github.com/Trisna22/UAC-Flooder",
            date: "22-06-2019",
            author: "Tris.Q",
            image: image6,
            tags: [
                "Windows", "Privilege escalation", "Source code"
            ],
        }
    ]

    return (
        <Container>
            <h1 style={titleStyle}>Blog</h1>
            <center>
            {
                blogs.map((blog) => (
                    <BlogElement title={blog.title} description={blog.description} 
                    url={blog.url} date={blog.date} tags={blog.tags} author={blog.author} 
                    image={blog.image}/>
                ))
            }
            </center>
        </Container>
    )
}

export default BlogPage;