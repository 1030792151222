// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../static/font/font.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@font-face {
    font-family: "Light";
    src: local("font"), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype")
}

body {
    font-family: "Light";
}

.BlogPostPage_introduction__Qd7cR {
    font-size: 18px;
    margin-top: 20px;
}

.BlogPostPage_paragraph__vkKCG {
    padding-top: 10px;
}

.BlogPostPage_paragraphPicture__fy3sL {
    flex-direction: row-reverse;
    margin-top: 20px;
}

.BlogPostPage_picture__XZwWg {
    max-width: 90%;
    margin: 10px;
    cursor: pointer;
}

.BlogPostPage_wideImage__prrJu {
    max-width: 80%;
}

.BlogPostPage_image__Gw3PB {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}

.BlogPostPage_blue__EMVJr {
    color: blue;
}
.BlogPostPage_red__tOv4K {
    color:red;
}
.BlogPostPage_gray__iTdBB {
    color: gray;
}

.BlogPostPage_paragraphTitle__5PuIE {
    margin-top: 20px;
}

@media screen and (min-width: 300px) {
    .BlogPostPage_paragraphPicture__fy3sL {
        flex-direction: row-reverse;
    }

    img {
        max-width: 100%;
    }
}

@media screen and (min-width: 600px) {
    
    .BlogPostPage_paragraphPicture__fy3sL {
        display: flex;
    }

    .BlogPostPage_picture__XZwWg {
        width: 250px;
    }
}

@media screen and (min-width: 1000px) {
    
    .BlogPostPage_paragraphPicture__fy3sL {
        display: flex;
    }

    .BlogPostPage_paragraph__vkKCG {
        font-size: 20px;
    }

    .BlogPostPage_introduction__Qd7cR {
        font-size: 20px;
    }

    .BlogPostPage_picture__XZwWg {
        width: 350px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/pages/Blog/BlogPostPage.module.css"],"names":[],"mappings":";AACA;IACI,oBAAoB;IACpB;AACJ;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,WAAW;AACf;AACA;IACI,SAAS;AACb;AACA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,2BAA2B;IAC/B;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;;IAEI;QACI,aAAa;IACjB;;IAEA;QACI,YAAY;IAChB;AACJ;;AAEA;;IAEI;QACI,aAAa;IACjB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":["\n@font-face {\n    font-family: \"Light\";\n    src: local(\"font\"), url(\"../../static/font/font.ttf\") format(\"truetype\")\n}\n\nbody {\n    font-family: \"Light\";\n}\n\n.introduction {\n    font-size: 18px;\n    margin-top: 20px;\n}\n\n.paragraph {\n    padding-top: 10px;\n}\n\n.paragraphPicture {\n    flex-direction: row-reverse;\n    margin-top: 20px;\n}\n\n.picture {\n    max-width: 90%;\n    margin: 10px;\n    cursor: pointer;\n}\n\n.wideImage {\n    max-width: 80%;\n}\n\n.image {\n    padding-left: 10px;\n    padding-right: 10px;\n    width: 100%;\n}\n\n.blue {\n    color: blue;\n}\n.red {\n    color:red;\n}\n.gray {\n    color: gray;\n}\n\n.paragraphTitle {\n    margin-top: 20px;\n}\n\n@media screen and (min-width: 300px) {\n    .paragraphPicture {\n        flex-direction: row-reverse;\n    }\n\n    img {\n        max-width: 100%;\n    }\n}\n\n@media screen and (min-width: 600px) {\n    \n    .paragraphPicture {\n        display: flex;\n    }\n\n    .picture {\n        width: 250px;\n    }\n}\n\n@media screen and (min-width: 1000px) {\n    \n    .paragraphPicture {\n        display: flex;\n    }\n\n    .paragraph {\n        font-size: 20px;\n    }\n\n    .introduction {\n        font-size: 20px;\n    }\n\n    .picture {\n        width: 350px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"introduction": `BlogPostPage_introduction__Qd7cR`,
	"paragraph": `BlogPostPage_paragraph__vkKCG`,
	"paragraphPicture": `BlogPostPage_paragraphPicture__fy3sL`,
	"picture": `BlogPostPage_picture__XZwWg`,
	"wideImage": `BlogPostPage_wideImage__prrJu`,
	"image": `BlogPostPage_image__Gw3PB`,
	"blue": `BlogPostPage_blue__EMVJr`,
	"red": `BlogPostPage_red__tOv4K`,
	"gray": `BlogPostPage_gray__iTdBB`,
	"paragraphTitle": `BlogPostPage_paragraphTitle__5PuIE`
};
export default ___CSS_LOADER_EXPORT___;
