import { Card, CardBody, CardHeader, Container } from "react-bootstrap";
import SkillTree from "../../components/SkillTree/SkillTree";
import linkedinCert from "../../static/img/linkedin_cert.png"
import css from "./SkillsPage.module.css"

const SkillsPage = () => {

    return (
        <Container>
            <h1>My skillset</h1>
            <span>
            I've acquired a diverse set of skills through my academic pursuits in 
            Cyber Security and Software Engineering, alongside self-directed 
            learning in programming. Collaborating with similarly driven individuals
            within a structured work environment is an aspect of professional 
            engagement that I find particularly rewarding.
            </span>
            <h3>Technology skills</h3>
            <SkillTree/>

            <h3>Certifications</h3>
            <div className={css.certificationDiv}>
                <Card className={css.certBox}>
                    <CardHeader>
                        Expert Webapplication Penetration Tester v2
                    </CardHeader>
                    <CardBody>
                        <img width={400} style={{maxWidth: "90%"}} src="https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/95668792" onClick={() => window.location.href = "https://certs.ine.com/99ad6b60-4f06-4377-96b9-79ad66d40226"}/>
                    </CardBody>
                </Card>
                <Card className={css.certBox}>
                    <CardHeader>
                        Computer Forensics
                    </CardHeader>
                    <CardBody>
                        <img width={400} style={{maxWidth: "90%", marginTop: "20px"}} onClick={() => window.location.href ="https://www.linkedin.com/learning/certificates/c50a0850d60254c2a651e9cbb7ddc10fc3c1b46a4dc75b8341fda9736a138d0f"} src={linkedinCert}/>
                    </CardBody>
                </Card>
            </div>
        </Container>
    )
    
}

export default SkillsPage;